import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { stringifyFilters } from '../../shared/utility';
import { Creators as UserCreators } from './user';

export const actionTypes = {
  GET_PROPOSALS: 'proposals/GET_ALL',
  GET_PROPOSALS_SUCCESS: 'proposals/GET_ALL_SUCCESS',
  GET_PROPOSALS_ERROR: 'proposals/GET_ALL_ERROR',
  RESET: 'proposals/RESET',
}

const initialState = {
  loading: false,
  error: null,
  data: {},
  filter: {},
  filter_info: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PROPOSALS:
      return { ...state, loading: true };
    case actionTypes.GET_PROPOSALS_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        data: action.payload.data, 
        filter: action.payload.filter,
        filter_info: action.payload.filter_info 
      };
    case actionTypes.GET_PROPOSALS_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.RESET:
      return { ...state, initialState };
    default:
      return state;
  }
}

export const Creators = {
  getProposals: (filter, filter_info, material_pendent) => async dispatch => {
    dispatch({ type: actionTypes.GET_PROPOSALS });
    try {
      filter = filter ?? {};
      filter_info = filter_info ?? {};
      filter['limit'] = '99999';
      if (material_pendent) {
        filter['material_pendent'] = 'true';
      }
      const filter_str = stringifyFilters(filter);
      const { data } = await axios.get(`${Endpoints.proposals}${filter_str}`);
      if (Object.keys(filter_info).length === 0) {
        const filter_info_data = await axios.get(`${Endpoints.proposals_info}`);
        if ((filter_info_data.data?.results?.length ?? 0) > 0) {
          filter_info = filter_info_data.data.results[0]; 
        }
      }
      dispatch({
        type: actionTypes.GET_PROPOSALS_SUCCESS,
        payload: { 
          data: data,
          filter: { ...filter },
          filter_info: filter_info
        }
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_PROPOSALS_ERROR,
          payload: { error: 'Não foi possível buscar as propostas no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_PROPOSALS_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_PROPOSALS_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_PROPOSALS_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getProposal: async (proposalId) => {
    try {
      const { data } = await axios.get(`${Endpoints.proposals}/${proposalId}`);
      return data;
    } catch (err) {
      return { error: 'Não foi possível buscar as observações no momento' };
    }
  },
  getProposalNotes: async (proposalId) => {
    try {
      const { data } = await axios.get(`${Endpoints.proposal_notes}?proposal_id=${proposalId}&limit=999`);
      return { data };
    } catch (err) {
      return { error: 'Não foi possível buscar as observações no momento' };
    }
  },
  createProposalNote: async (proposalId, analystId, text) => {
    try {
      const { data } = await axios.post(`${Endpoints.proposal_notes}`, {'proposal_id': proposalId, 'analyst_id': analystId, 'text': text});
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar a observação. Por favor, tente novamente' };
    }
  },
  deleteProposalNote: async (noteId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.proposal_notes}/${noteId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar deletar a observação. Por favor, tente novamente' };
    }
  },
  createProposal: async (fields, files) => {
    try {
      const { data } = await axios.post(`${Endpoints.proposals}`, fields);
      if (files && files.length > 0) {
        await Creators.uploadProposalFiles(data.id, files);
      }
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar a proposta. Por favor, tente novamente' };
    }
  },
  updateProposal: async (proposalId, fields, files) => {
    try {
      delete fields.id;
      const { data } = await axios.patch(`${Endpoints.proposals}/${proposalId}`, fields);
      if (files && files.length > 0) {
        await Creators.uploadProposalFiles(proposalId, files);
      }
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar atualizar a proposta. Por favor, tente novamente' };
    }
  },
  uploadProposalFiles: async (proposalId, files) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      const formData = new FormData();
      for(let i=0; i<files.length; i++) {
        formData.append(`file${i}.name`, files[i].name);
        formData.append(`file${i}.file`, files[i]);
      }
      await axios.post(`${Endpoints.proposals}/${proposalId}/files`, formData, config);
    } catch (e) {
      console.error(e);
    }
  },
  getNotes: async (proposalId) => {
    try {
      const { data } = await axios.get(`${Endpoints.proposal_notes}?proposal_id=${proposalId}&limit=999`);
      return { data };
    } catch (err) {
      return { error: 'Não foi possível buscar as observações no momento' };
    }
  },
  getPendentCount: async () => {
    try {
      const { data } = await axios.get(Endpoints.proposals_count);
      const results = data.results ?? [{}];
      return results[0].material_pendent;
    } catch (err) {
      return { error: 'Não foi possível buscar as observações no momento' };
    }
  },
  resetProposals: () => ({ type: actionTypes.RESET })
};