import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import * as S from './styles';
import { DEFAULT_COLOR } from '../../constants/layout';

const CenteredLoader = ({ size }) => {
    return (
        <S.LoaderContainer size={size}>
            <TailSpin color={DEFAULT_COLOR} width={size ?? 80} height={size ?? 80} />
        </S.LoaderContainer>
    );
}

export default CenteredLoader;