import React, { Suspense, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import ProposalFileView from '../pages/loans/ProposalFileView.js';

// lazy load all the views

// account
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/account/ResetPassword'));

//pages
const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard'));
const PanelLoans = React.lazy(() => import('../pages/panel/PanelLoans'));
const PanelInsurance = React.lazy(() => import('../pages/panel/PanelInsurance'));
const PanelForesight = React.lazy(() => import('../pages/panel/PanelForesight'));
const ProposalsAll = React.lazy(() => import('../pages/loans/ProposalsAll'));
const ProposalNew = React.lazy(() => import('../pages/loans/ProposalNew.js'));
const ProposalsPendent = React.lazy(() => import('../pages/loans/ProposalsPendent'));
const Simulator = React.lazy(() => import('../pages/loans/Simulator'));
const Insurance = React.lazy(() => import('../pages/insurance/Insurance'));
const Messages = React.lazy(() => import('../pages/messages/Messages'));
const Files = React.lazy(() => import('../pages/files/Files'));
const Profile = React.lazy(() => import('../pages/account/Profile'));
const Users = React.lazy(() => import('../pages/users/Users'));
const UserNew = React.lazy(() => import('../pages/users/UserNew'));

const loading = () => <div className=""></div>;

const LoadComponent = ({ component: Component, searchOptions, setSearchOptions }) => (
    <Suspense fallback={loading()}>
        <Component searchOptions={searchOptions} setSearchOptions={setSearchOptions} />
    </Suspense>
);

const AllRoutes = () => {
    const [searchOptions, setSearchOptions] = useState({
        executeSearch: false,
        showSearch: true,
        showButton: true,
        screen: '',
        fields: {
            search: {
                label: 'Pesquisar...',
                type: 'input',
                value: '',
            },
        },
    });

    let Layout = VerticalLayout;

    return useRoutes([
        { path: '/', element: <Root /> },
        {
          path: 'proposals-files/:proposalId/:fileId/:fileName',
          element: (
            <ProposalFileView />
          ),
        },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'reset-password/:uid/:token', element: <LoadComponent component={ResetPassword} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: (
                <PrivateRoute component={Layout} searchOptions={searchOptions} setSearchOptions={setSearchOptions} />
            ),
            children: [
                {
                    path: 'dashboard',
                    element: (
                        <LoadComponent
                            component={Dashboard}
                            searchOptions={searchOptions}
                            setSearchOptions={setSearchOptions}
                        />
                    ),
                },
                {
                    path: 'proposals',
                    element: (
                        <LoadComponent
                            component={ProposalsAll}
                            searchOptions={searchOptions}
                            setSearchOptions={setSearchOptions}
                        />
                    ),
                },
                {
                  path: 'proposals/:proposalId',
                  element: (
                    <LoadComponent
                      component={ProposalNew}
                      searchOptions={searchOptions}
                      setSearchOptions={setSearchOptions}
                    />
                  ),
                },  
                {
                    path: 'simulator',
                    element: (
                      <LoadComponent
                        component={Simulator}
                        searchOptions={searchOptions}
                        setSearchOptions={setSearchOptions}
                      />
                    ),
                  },
                {
                    path: 'pendent',
                    element: (
                        <LoadComponent
                            component={ProposalsPendent}
                            searchOptions={searchOptions}
                            setSearchOptions={setSearchOptions}
                        />
                    ),
                },
                {
                    path: 'insurance',
                    element: (
                        <LoadComponent
                            component={Insurance}
                            searchOptions={searchOptions}
                            setSearchOptions={setSearchOptions}
                        />
                    ),
                },
                {
                    path: 'messages',
                    element: (
                        <LoadComponent
                            component={Messages}
                            searchOptions={searchOptions}
                            setSearchOptions={setSearchOptions}
                        />
                    ),
                },
                {
                    path: 'panel',
                    children: [
                        {
                            path: 'panel-loans',
                            element: (
                                <LoadComponent
                                    component={PanelLoans}
                                    searchOptions={searchOptions}
                                    setSearchOptions={setSearchOptions}
                                />
                            ),
                        },
                        {
                            path: 'panel-foresight',
                            element: (
                                <LoadComponent
                                    component={PanelForesight}
                                    searchOptions={searchOptions}
                                    setSearchOptions={setSearchOptions}
                                />
                            ),
                        },
                        {
                            path: 'panel-insurance',
                            element: (
                                <LoadComponent
                                    component={PanelInsurance}
                                    searchOptions={searchOptions}
                                    setSearchOptions={setSearchOptions}
                                />
                            ),
                        },
                    ],
                },
                {
                    path: 'files',
                    element: (
                        <LoadComponent
                            component={Files}
                            searchOptions={searchOptions}
                            setSearchOptions={setSearchOptions}
                        />
                    ),
                },
                {
                    path: 'users',
                    element: (
                        <LoadComponent
                            component={Users}
                            searchOptions={searchOptions}
                            setSearchOptions={setSearchOptions}
                        />
                    ),
                },
                {
                    path: 'users/:userId',
                    element: (
                        <LoadComponent
                            component={UserNew}
                            searchOptions={searchOptions}
                            setSearchOptions={setSearchOptions}
                        />
                    ),
                },
                {
                    path: 'account',
                    children: [
                        {
                            path: 'profile',
                            element: (
                                <LoadComponent
                                    component={Profile}
                                    searchOptions={searchOptions}
                                    setSearchOptions={setSearchOptions}
                                />
                            ),
                        },
                    ],
                },
            ],
        },
    ]);
};

export { AllRoutes };
