import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { containsProfile } from '../shared/utility';

const Root = () => {
    const loggedUser = useSelector((state) => state.user.data);

    const getRootUrl = () => {
        const hasPanelAccess = containsProfile(loggedUser, [
            'admin',
            'panel_loans_operational',
            'panel_loans_manager',
            'panel_foresight_operational',
            'panel_foresight_manager',
            'panel_insurance_operational',
            'panel_insurance_manager',
        ]);
        let url = hasPanelAccess ? 'dashboard' : 'account/profile';
        return url;
    };

    const url = getRootUrl();

    return <Navigate to={`/${url}`} />;
};

export default Root;
